.ant-picker {
  height: calc(1.5em + 0.75rem + 2px);
}
.ant-picker-range {
  display: inline-flex !important;
}
.ant-table-filter-trigger-icon {
  position: absolute;
  top: calc(50% - 0.5em);
  left: calc(50% - 0.5em);
}
.ant-select-selector {
  border-radius: 4px !important;
  min-height: calc(1.5em + 0.75rem + 2px) !important;
}
.ant-select-selection-placeholder {
  margin: auto;
}
.ant-select-selection-item {
  margin: auto;
}
.ant-select-multiple .ant-select-selection-item {
  margin: 0;
}
.rbt-aux {
  align-items: center;
  display: flex;
  bottom: 0;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
}
.rbt-menu.dropdown-menu {
  padding: 4px 0;
  border: unset;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.rbt-menu .dropdown-item {
  border-bottom: unset;
  padding: 5px 12px;
}
.rbt-menu .dropdown-item.active,
.dropdown-item:active {
  color: rgba(0, 0, 0, 0.85);
  background-color: #e6f7ff;
}
.disabled-content {
  pointer-events: none;
  opacity: 0.5;
}
.disabled-content .modal-content {
  pointer-events: none;
}
.input--placeholder {
  position: relative;
  display: block;
  width: 100%;
}
.input--placeholder input {
  background-color: #ffffff !important;
}
.input--placeholder input:focus,
.input--placeholder input:hover {
  border-color: rgb(0, 0, 0);
  box-shadow: 0 0 0 1px rgb(0, 0, 0);
}
.input--placeholder .placeholder {
  position: absolute;
  top: 50%;
  left: 10px;
  display: block;
  padding: 0 10px;
  color: #95989a;
  white-space: nowrap;
  letter-spacing: 0.2px;
  font-weight: normal;
  font-size: 16px;
  -webkit-transition: all, 0.2s;
  transition: all, 0.2s;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.input--placeholder.active .placeholder {
  top: -5px;
  background-color: #ffffff;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-size: 11px;
  line-height: 14px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
